import React from "react"
import styled from "@emotion/styled"
import { Head } from "../components/Head"

const Root = styled.div`
  background-color: #111;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  font-family: PirataOne;
  font-size: 3rem;
  line-height: 1;
  color: #fff;
`

const NotFound: React.FC = () => {
  return (
    <Root>
      <Head lang={process.env.LANGUAGE || ""}>
        <meta name="title" content="404" />
        <meta name="description" content="Page not found" />
      </Head>
      <Title>404</Title>
    </Root>
  )
}

export default NotFound
